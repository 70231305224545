import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../otherPage.css"

import girl09 from "../images/girl09.png"
import appstore from "../images/appstore.png"

const HitaiouPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="非対応デバイス" />
      <section className="Hitaiou">
        <div className="wrap">
          <img src={ girl09 } className="girl09"/>
          <div>
            <h2>申し訳ございません。</h2>
            <h1>NuRseCallアプリ<br></br>非対応デバイスです</h1>       
            <p>現在、NuRseCallアプリは、iOSのみご利用いただけます。<br></br>
            今後は、Android向けアプリをリリース予定です。<br></br>
            リリースまでもうしばらくお待ちください。</p>
          </div>
          <div className="download-box">
            <span>ダウンロードはこちら</span>
            <a href="https://apps.apple.com/jp/app/nursecall/id1539839928" target="_blank">
              <img src={ appstore } className="appstore" alt="App Storeからダウンロード" />
            </a>
          </div>
        </div>
        <div className="area">
            <ul class="circles">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
      </section>
    </Layout>
  )
}

export default HitaiouPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
